<template>
    <div class="mt-10 f-c">
        <VueEmoji :width="'300'" @input="onInput" :value="commentValue" />
        <el-button class="ml-30 " type="primary" @click="publicBtn" round>发表</el-button>
    </div>
</template>

<script>
import VueEmoji from 'emoji-vue'
export default {
    name: '',
    components: { VueEmoji },
    props: ['commonItem', 'CommentType'],

    data() {
        return {
            commentValue: '',//评论内容
        }
    },
    methods: {
        onInput(event) {
            this.commentValue = event.data
        },
        publicBtn() {
            let that = this;
            this.$emit('SecondaryComment', {
                value: that.commentValue,
                item: that.CommentType == 'first' ? that.commonItem : that.$getStorage('SecondaryParams').firstComment,
            })
            this.commentValue = ''

        },
    },
}

</script>
<style lang='less' scoped></style>
