<template>
    <div>
        <div class="mb-20 f-c" v-for="(item, index) of commentList" :key="index">
            <el-image class="wh-4z0 borderRa-50 mr-15 alignSelf-start" :src="item.userAvatarUrl"></el-image>
            <div class="f-cl f1">
                <div class="f-c-b">
                    <div class=" f-c color-dd fs-15" v-if="CommentType == 'first'">
                        <span>{{ item.fcommentUsername }}</span>
                        <template v-if="DetailInfo">
                            <span v-show="item.fcommentUserId == DetailInfo.postUserId" class="circle"> </span>
                            <span v-show="item.fcommentUserId == DetailInfo.postUserId">作者</span>
                        </template>
                    </div>
                    <!-- <div v-if="CommentType == 'second'" class=" f-c">{{ item.mcommentUsername }}
                        <span v-if="item.connectMcommentUsername" class="triangle"></span>
                        <span>{{ item.connectMcommentUsername }}</span>
                    </div> -->
                    <el-popover placement="bottom-start" trigger="click" v-if="item.fcommentUserId == userId || item.mcommentUserId == userId">
                        <el-button @click="removeItem(item)">删除</el-button>
                        <svg slot="reference" width="16" height="16" viewBox="0 0 24 24" class="ZDI ZDI--Dots24 css-zgte1c"
                            fill="currentColor">
                            <path
                                d="M5.34 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0ZM13.665 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0ZM21.99 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0Z">
                            </path>
                        </svg>
                    </el-popover>
                </div>
                <div class="marginTB-10">{{ CommentType == 'first' ? item.fcommentConent : item.mcommentConent }}</div>
                <div class="f-c color-bb fs-14">{{ item.createDate }}
                    <span class="circle" v-show="item.fcommentLocation"> </span>
                    {{ item.fcommentLocation }}
                    <el-button class="ml-50 paddingTB-5 paddingLR-10" @click="showInput(index)">回复</el-button>
                </div>
                <CommonInput v-show="item.showCommonInput" @SecondaryComment="SecondaryComment" :commonItem="item"
                    :CommentType="CommentType">
                </CommonInput>
                <template v-if="showCheck">
                    <span v-if="item.fcomments"
                        class="borderRa-50 ta-center mt-10 alignSelf-start bg-d0 paddingTB-10 paddingLR-15 hover-pointer"
                        @click="showSecondCommontBox(item.postId, item.fcommentId, item)">查看全部 {{ item.fcomments }}
                        条回复</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import CommonInput from './commonInput.vue';

export default {
    name: 'CommentItem',
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        DetailInfo: {},
        // first 一级评论 second 二级
        CommentType: {
            type: String,
            default: ''
        },
        // 是否展示二级列表按钮
        showCheck: {
            type: Boolean,
            default: false
        },
        queryType: {}
    },
    watch: {
        list() {
            this.commentList = this.list
        }
    },
    components: {
        CommonInput
    },
    data() {
        return {
            userId: this.$getStorage('userId'),
            commentList: []
        }
    },
    methods: {
        showInput(idx) {
            this.commentList.forEach((item, index) => {
                if (index != idx) {
                    item.showCommonInput = false
                } else {
                    item.showCommonInput = true
                    window.addEventListener("scroll", this.scrolling);
                }
            })
        },

        scrolling() {
            setTimeout(() => {
                this.commentList.forEach((item) => {
                    item.showCommonInput = false
                })
            }, 1000);
        },

        // 显示二级评论盒子
        showSecondCommontBox(postId, fcommentId, item) {
            this.$emit('showSecondCommontBox', { postId, fcommentId, firstComment: item })
        },
        removeItem(item) {
            switch (this.queryType) {
                case 'ShiLiaoZaTan':
                    this.CommentType == 'first' ? this.removeForumChatPostComment(item) : this.removeForumChatPostSecondaryComment(item)
                    break;
                case 'ProductExchange':
                    this.CommentType == 'first' ? this.removeForumPostComment(item) : this.removeForumPostSecondaryComment(item)
                    break;
            }
        },
        // 获取移除一级评论参数
        getRemoveParams(data) {
            let that = this, { fcommentUserId, fcommentId, postId } = data;
            return {
                fcommentUserId,
                fcommentId,
                postId,
                token: that.$getStorage('token'),
            }
        },
        //产品交流-- 一级评论模块-- 用户移除评论接口
        removeForumPostComment(data) {
            let that = this;
            that.$confirm('是否删除该评论？', '提示').then(() => {
                that.$http.removeForumPostComment(that.getRemoveParams(data)).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1500)
                        that.$parent.reload()
                    }
                })
            }).catch(() => { })
        },
        // 获取移除二级评论参数
        getSecondaryRemoveParams(data) {
            let that = this, { mcommentUserId, mcommentId, postId } = data;
            return {
                mcommentUserId,//用户id
                mcommentId,//评论id
                postId,
                token: that.$getStorage('token'),
            }
        },
        //产品交流 -- 一级评论模块-- 用户移除二级评论
        removeForumPostSecondaryComment(data) {
            let that = this;
            that.$confirm('是否删除该评论？', '提示').then(() => {
                that.$http.removeForumPostSecondaryComment(that.getSecondaryRemoveParams(data)).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1500)
                        that.$emit('uploadSecondaryList')
                    }
                })
            }).catch(() => { })
        },

        // 识料杂谈帖子模块---一级评论模块 -- 用户移除评论接口
        removeForumChatPostComment(data) {
            let that = this;
            that.$confirm('是否删除该评论？', '提示').then(() => {
                that.$http.removeForumChatPostComment(that.getRemoveParams(data)).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1500)
                        that.$parent.reloadList()
                    }
                })
            }).catch(() => { })
        },
        // 识料杂谈帖子模块---二级评论模块 -- 用户移除评论接口
        removeForumChatPostSecondaryComment(data) {
            let that = this;
            that.$confirm('是否删除该评论？', '提示').then(() => {
                that.$http.removeForumChatPostSecondaryComment(that.getSecondaryRemoveParams(data)).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1500)
                        that.$emit('uploadSecondaryList')
                    }
                })
            }).catch(() => { })
        },


        // 二级评论---------------------------------
        getSecondaryCommentParams(data) {
            let that = this,
                { fcommentLocation, sourceIp } = that.$getStorage('getIpAddress'),
                { postId, fcommentId, fcommentUserId, fcommentUsername } = data.item;
            return {
                mcommentUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                postId,
                connectFcommentId: fcommentId,
                connectMcommentUserId: fcommentUserId,
                connectMcommentUsername: fcommentUsername,
                mcommentConent: data.value,
                mcommentLocation: fcommentLocation,
                sourceIp,
                mobject: '',
            }
        },
        getSecondaryCommentSuccess(res) {
            let that = this;
            if (res.code == 200) {
                that.$common.message(res.msg, 'success')
                if (that.CommentType == 'first') {
                    setTimeout(() => {
                        that.$parent.reload()
                    }, 800);
                } else {
                    that.$emit('secondaryBoxComment')
                }

            }
        },

        // 产品供应帖子---二级评论
        addForumPostSecondaryComment(data) {
            let that = this;
            that.$http.addForumPostSecondaryComment(that.getSecondaryCommentParams(data)).then(res => {
                that.getSecondaryCommentSuccess(res)
            })
        },
        // 识料杂谈帖子---二级评论
        // addForumChatPostSecondaryComment(data) {
        //     let that = this;
        //     that.$http.addForumChatPostSecondaryComment(that.getSecondaryCommentParams(data)).then(res => {
        //         that.getSecondaryCommentSuccess(res)
        //     })
        // },



        // 二级评论发表
        SecondaryComment(data) {
            let that = this;
            switch (that.queryType) {
                case 'ProductExchange'://产品交流
                    that.addForumPostSecondaryComment(data);
                    break;
                case 'ShiLiaoZaTan'://识料杂谈
                    that.addForumChatPostSecondaryComment(data)
                    break;

            }
        },

    }
}

</script>